<template>
  <div></div>
</template>

<script>
import { getlistdata } from "../../../util/api"
export default {
  name: 'science_child2',
  data() {
    return {
      page: 1,
      currpage: 10,
      totalCount: 0,
      tableData: []
    }
  },
  mounted() {
  },
  created() {
    this.getlist()
    console.log(localStorage.getItem('phone'));
  },
  methods: {
    getlist() {
      getlistdata({
        phone: localStorage.getItem('phone')
      }).then((res) => {
        console.log(res.data);
        this.tableData = res.data;
      })
    },
    handleSizeChange(cpage) {
      this.currpage = cpage;
      this.getlist();
    },
    //分页按钮 首页和最后一页
    handleCurrentChange(cpage) {
      this.page = cpage;
      this.getlist();
    },
  }
}
</script>
<style lang="less">
.science_child4 {
  padding-bottom: 0.8rem;

  .science_title {
    padding-top: 0.6rem;
    font-size: 0.36rem;
    color: #353535;
    font-weight: bold;
    text-align: center;
  }

  .science_title_en {
    color: #999999;
    font-size: 0.18rem;
  }

  .science_Nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.3rem;
  }

  .science_NavLi {
    width: 1.18rem;
    height: 0.38rem;
    text-align: center;
    color: #9c9c9c;
    font-size: 0.14rem;
    line-height: 0.38rem;
    border: 1px solid #eeeeee;
    margin: 0 0.1rem;
    cursor: pointer;
  }

  .science_NavLi_active {
    width: 1.18rem;
    height: 0.38rem;
    text-align: center;
    color: #ffffff;
    font-size: 0.14rem;
    line-height: 0.38rem;
    background-color: #ffb900;
    border: 1px solid #ffb900;

  }


  .box1Title {
    width: 9.6rem;
    font-size: 0.24rem;
    color: #333333;
    margin: 0.5rem auto 0;
    line-height: 0.4rem;
    text-align: center;
  }

  .box1Count_div {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .box1Count_li {
    margin: 0 0.5rem;
    text-align: center;
  }

  .box1Count_liA {
    font-size: 0.48rem;
    color: #ffb900;
  }

  .box1Count_liA span {
    font-size: 0.14rem;
    color: #ffb900;
  }

  .box1Count_liB {
    font-size: 0.14rem;
    color: #222222;
    text-align: center;
  }

  .box1Map {
    position: relative;
    margin-top: 0.8rem;
  }

  .box1Map_img {
    width: 100%;
  }

  .box1Map_li {
    position: absolute;
    width: 1.07rem;
    height: 1.07rem;
    border-radius: 100%;
    border: 1px solid #ffffff;
    cursor: pointer;
    transition: all 0.5s;
    color: transparent;
  }

  .box1Map_li:hover {
    transform: scale(1.3);
    border: 2px solid #ffba05;
    color: #333333;
  }

  .box1Map_img {
    width: 100%;
  }

  .box1Map_text {
    position: absolute;
    height: 1rem;
    bottom: -1.1rem;
    font-size: 0.16rem;
    text-align: center;
    width: 100%;

  }

  .box1_li0 {
    top: 0;
    left: 38%;
  }

  .box1_li1 {
    top: 1rem;
    left: 16%;
  }

  .box1_li2 {
    top: 1rem;
    left: 81%;
  }

  .box1_li3 {
    top: 2rem;
    left: 55%;
  }

  .box1_li4 {
    top: 3rem;
    left: 36%;
  }

  .box1_li5 {
    top: 5.16rem;
    left: 10.35%;
  }

  .box1_li6 {
    top: 5.6rem;
    left: 52.8%;
  }

  .box1_li7 {
    top: 5rem;
    left: 87%;
  }



  .box2list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-right: -0.6rem;
  }

  .box2li {
    width: 29%;
    height: 5rem;
    border: 1px solid #dfdfdf;
    margin: 0.6rem 0.6rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }

  .box2li_img {
    height: 40%;
    display: block;
    margin: 0 auto;
  }

  .box2li_line {
    width: 20px;
    height: 2px;
    background-color: #ffb900;
    margin: 0.25rem auto;
  }

  .box2li_text {
    text-align: center;
    color: #393939;
    font-size: 0.2rem;
    font-weight: bold;

  }




  .reportfont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.6rem;
  }

  .my-btn {
    width: 0.8rem;
    height: 0.4rem;
    line-height: 0.4rem;
    text-align: center;
    background-color: #ffffff;
    color: #868686;
    font-size: 0.14rem;
    border-radius: 0px;
    cursor: pointer;
    border: 1px solid #eeeeee;
  }

  .my-btn span {
    font-size: 0.14rem;
    font-weight: bold;
  }

  .el-pagination {
    text-align: center;
    padding: 0;
  }


  .el-pagination.is-background button {
    width: 0.4rem;
    height: 0.4rem;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    color: #868686;
    font-size: 0.14rem;
    border-radius: 0px;
  }

  .el-pagination.is-background button:not(.disabled) {
    width: 0.4rem;
    height: 0.4rem;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    color: #868686;
    font-size: 0.14rem;
    border-radius: 0px;
  }

  .el-pagination.is-background .el-pager li:not(.disabled) {
    width: 0.4rem;
    height: 0.4rem;
    line-height: 0.4rem;
    font-size: 0.14rem;
    background-color: #f4f4f4;
    color: #868686;
    border-radius: 0px;
    font-weight: normal;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #ffb900;
    color: #fff;
  }

  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ffb900;
  }




  /*当浏览器尺寸小于1660px时候的代码：*/
  @media screen and (max-width: 1680px) {

    .box2li {
      width: 28%;
    }
  }

  @media screen and (max-width: 1560px) {}

  @media screen and (max-width: 1400px) {
    .box2li {
      width: 27%;
      height: 4rem;
    }

    .box2li_img {
      // width: 57%;
    }
  }

  @media screen and (max-width: 1200px) {}

  /* 移动端 */
  @media screen and (max-width: 1024px) {}

  @media screen and (max-width: 768px) {}

  @media screen and (max-width: 414px) {}

  @media screen and (max-width: 375px) {}









}
</style>